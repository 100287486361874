import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

export const languageOptions = [
  { code: 'bg', name: 'BG - български' },
  { code: 'zh', name: 'ZH - 中文' },
  { code: 'cs', name: 'CS - čeština', IMRContent: 'CP-467772 - 07/2024', IMRContentTOU: 'CP-467772 - 07/2024' },
  { code: 'da', name: 'DA - dansk' },
  { code: 'nl', name: 'NL - Nederlands', IMRContent: 'EM-162555 - 07/2024', IMRContentTOU: 'EM-162555 - 07/2024' },
  { code: 'en', name: 'EN - English' },
  { code: 'et', name: 'ET - eesti', IMRContent: 'EM-161984 - 07/2024', IMRContentTOU: 'EM-161988 - 07/2024' },
  { code: 'fi', name: 'FI - suomi' },
  { code: 'fr', name: 'FR - français' },
  { code: 'de', name: 'DE - Deutsch' },
  { code: 'el', name: 'EL - Ελληνικά' },
  { code: 'hr', name: 'HR - Hrvatski' },
  { code: 'hu', name: 'HU - magyar', IMRContent: 'CP-468751 - 08/2024', IMRContentTOU: 'CP-469784 - 08/2024' },
  { code: 'is', name: 'IS - íslenska' },
  { code: 'it', name: 'IT - italiano' },
  { code: 'ko', name: 'KO - 한국어' },
  { code: 'lv', name: 'LV - latviešu', IMRContent: 'EM-163624 - 08/2024', IMRContentTOU: 'EM-163625 - 08/2024' },
  { code: 'lt', name: 'LT - lietuvių', IMRContent: 'EM-163627 - 08/2024', IMRContentTOU: 'EM-163626 - 08/2024' },
  { code: 'no', name: 'NO - norsk' },
  { code: 'pl', name: 'PL - polski', IMRContentTOU: 'CP-470559 - 08/2024' },
  { code: 'pt', name: 'PT - português' },
  { code: 'ro', name: 'RO - română' },
  { code: 'sk', name: 'SK - slovenčina', IMRContentTOU: 'CP-470910 - 08/2024' },
  { code: 'sl', name: 'SL - slovenščina', IMRContentTOU: 'EM-164569 - 08/2024' },
  { code: 'es', name: 'ES - español', IMRContent: 'EM-164412 - 08/2024', IMRContentTOU: 'EM-164412 - 08/2024' },
  { code: 'sv', name: 'SV - svenska' },
  { code: 'th', name: 'TH - ไทย' },
  { code: 'tr', name: 'TR - Türkçe' },
].sort((a, b) => a.code.localeCompare(b.code));

function LanguageList() {
  const { i18n } = useTranslation();
  const userLanguage = navigator.language.split('-')[0];
  const lastLanguage = localStorage.getItem('language') || userLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState(lastLanguage);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    localStorage.setItem('language', newLanguage);
    setSelectedLanguage(newLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Select
      id="dropdown"
      value={selectedLanguage}
      onChange={handleLanguageChange}
      className="custom-select-language"
      style={{ marginRight: '8px' }}
    >
      {languageOptions.map((language) => (
        <MenuItem key={language.code} value={language.code}>
          <Typography variant="body2">{language.name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
LanguageList.propTypes = {
  customStyles: PropTypes.object,
};
LanguageList.defaultProps = {
  customStyles: {}, // Set a default empty object or any other default styles
};
export default LanguageList;
